import _ from 'lodash';

export default {
    data: function () {
        return {
            service: '',
            reloadBus: null,
            defaultFilterFormModel: {
                page: 1,
                size: 50,
                parameters: []
            },
            autoLoad: true,
            reloadInterval: 0,
            filterFormModel: {},
            items: [],
            itemCount: 0,
            reloadTimer: null,
            listLoading: false,
            firstLoading: true,
            firstItemOfPage: 0,
            lastItemOfPage: 0,
            isEmpty: true,
            params: {}
        }
    },
    methods: {
        search() {
            this.filterFormModel.page = 1;
            this.load();
        },
        load() {
            let loadingDelay = _.delay(() => {
                this.listLoading = true;
            }, 250);
            let filter = Object.assign({}, this.filterFormModel);
            Object.keys(filter).forEach(key => {
                if (typeof filter[key] === 'object' && filter[key]) {
                    filter[key] = filter[key].join(",");
                }
            });
            this.$service.get(this.service, {
                params: filter
            }).then(response => {
                this.firstLoading = false;
                this.apply(response.data);
                if (this.beforeLoad) {
                    this.beforeLoad(response.data);
                }
            }).catch(error => {
                if (error.response.data.message) {
                    this.$swal({
                        text: error.response.data.message,
                        icon: 'error'
                    });
                }
            }).finally(() => {
                clearTimeout(loadingDelay);
                this.listLoading = false;
            });
        },
        apply(response) {
            this.items = response.data;
            this.itemCount = response.dataCount;
            this.params = response.params;
            this.firstItemOfPage = this.itemCount > 0 ? (this.filterFormModel.page - 1) * this.filterFormModel.size + 1 : 0;
            this.lastItemOfPage = ((this.filterFormModel.page - 1) * this.filterFormModel.size) + this.items.length;
            this.isEmpty = this.itemCount <= 0;
        },
        reset() {
            this.filterFormModel = Object.assign({}, this.defaultFilterFormModel);
            this.load();
        },
        setPage(page) {
            this.filterFormModel.page = page;
            this.load();
        },
        copy(str) {
            this.$copyText(str).then(() => {
                this.$swal({
                    text: `Đã copy ${str}`,
                    icon: 'success',
                    toast: true,
                    position: 'bottom-end',
                    timer: 3000
                });
            }, () => {
                this.$swal({
                    title: "Có lỗi",
                    text: "Copy thất bại",
                    icon: 'error'
                });
            })
        },
        getLink(path) {
            return `${this.$store.state.etc['site-frontend-url']}${path}`;
        }
    },
    mounted() {
        this.filterFormModel = Object.assign({}, this.defaultFilterFormModel);
        if (this.autoLoad) {
            this.load();
            this.firstLoading = true;
        }
        if (this.reloadInterval > 0) {
            this.reloadTimer = setInterval(() => {
                this.$service.get(this.controller + '/' + this.actions.list, {
                    params: this.filterFormModel
                }).then(response => {
                    this.apply(response.data);
                });
            }, this.reloadInterval);
        }
        if (this.reloadBus) {
            this.$bus.$on(this.reloadBus, this.load);
        }
    },
    beforeDestroy() {
        if (this.reloadTimer !== null) {
            clearInterval(this.reloadTimer);
        }
        if (this.reloadBus) {
            this.$bus.$off(this.reloadBus);
        }
    }
};