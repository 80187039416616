export default {
    data: function () {
        return {
            service: '',
            reloadBus: null,
            defaultFormModel: {},
            showForm: false,
            formModel: {},
            formTitle: '',
            formErrors: {},
            updateLoading: false,
            saveLoading: false,
            delLoading: false,
        }
    },
    methods: {
        create: function () {
            this.formModel = {};
            this.formModel = Object.assign({}, this.defaultFormModel);
            this.formTitle = "Thêm mới";
            this.formErrors = {};
            this.showForm = true;
        },
        update: function (id) {
            let uri = this.service;
            if (id) {
                uri += '/get?id=' + id;
            }
            this.updateLoading = true;
            this.$service.get(uri).then(response => {
                if (this.beforeUpdate) {
                    this.beforeUpdate(response.data);
                }
                this.formModel = {};
                this.formTitle = "Chỉnh sửa";
                this.formErrors = {};
                this.showForm = true;
                this.formModel = response.data;
                this.formModel.id = id;
            }).finally(() => {
                this.updateLoading = false;
            });
        },
        save: function (evt) {
            if (evt) {
                evt.preventDefault();
            }
            this.saveLoading = true;
            let method = 'post';
            let url = this.service;
            if (this.formModel.id) {
                method = 'put';
                url += `/update?id=${this.formModel.id}`;
            } else {
                method = 'post';
                url += '/create';
            }
            if (this.beforeSave) {
                this.beforeSave();
            }
            return this.$service({url: url, method: method, data: this.formModel}).then(response => {
                this.showForm = false;
                if (this.load) {
                    this.load();
                }
                if (this.reloadBus) {
                    this.$bus.$emit(this.reloadBus);
                }
                if (response.data) {
                    this.$swal({
                        title: "Thành công",
                        text: this.formModel.id ? 'Chỉnh sửa thành công' : 'Thêm mới thành công',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-end',
                        timer: 5000,
                    });
                } else {
                    this.$swal({
                        title: "Có lỗi",
                        text: this.formModel.id ? 'Chỉnh sửa thất bại' : 'Thêm mới thất bại',
                        icon: 'error',
                        toast: true,
                        position: 'bottom-end',
                        timer: 5000,
                    });
                }
                return response.data;
            }).catch(error => {
                if (error.response.data.message) {
                    this.$swal({
                        text: error.response.data.message,
                        icon: 'error'
                    });
                    this.showForm = false;
                } else {
                    this.$swal({
                        text: "Vui lòng kiểm tra lại thông tin",
                        icon: 'warning',
                        toast: true,
                        position: 'bottom-end',
                        timer: 3000,
                    });
                    this.formErrors = error.response.data;
                }
            }).finally(() => {
                this.saveLoading = false;
            });
        },
        del: function (id) {
            this.$swal({
                title: "Bạn chắc chắn muốn xóa chứ?",
                text: "Sẽ không thể khôi phục lại dữ liệu đã xóa!",
                icon: "warning",
                showCancelButton: true
            }).then((result) => {
                if (result.value) {
                    this.delLoading = true;
                    this.$service.delete(`${this.service}/del?id=${id}`).then(response => {
                        if (response.data) {
                            if (this.load) {
                                this.load();
                            }
                            if (this.reloadBus) {
                                this.$bus.$emit(this.reloadBus);
                            }
                            this.$swal({
                                title: "Thành công",
                                text: "Xoá thành công",
                                icon: 'success',
                                toast: true,
                                position: 'bottom-end',
                                timer: 5000,
                            });
                        } else {
                            this.$swal({
                                title: "Có lỗi",
                                text: "Xoá không thành công",
                                icon: 'error',
                                toast: true,
                                position: 'bottom-end',
                                timer: 5000,
                            });
                        }
                    }).catch(error => {
                        if (error.response.data.message) {
                            this.$swal({
                                text: error.response.data.message,
                                icon: 'error'
                            });
                        }
                    }).finally(() => {
                        this.delLoading = false;
                    });
                }
            });
        },
        dels: function (models) {
            this.$swal({
                title: "Bạn chắc chắn muốn xóa chứ?",
                text: "Sẽ không thể khôi phục lại dữ liệu đã xóa!",
                icon: "warning",
                showCancelButton: true
            }).then(async (result) => {
                if (result.value) {
                    this.delLoading = true;
                    for (let item of models) {
                        await this.$service.delete(`${this.service}/del?id=${item.id}`);
                    }
                    this.delLoading = false;
                    if (this.load) {
                        this.load();
                    }
                    if (this.reloadBus) {
                        this.$bus.$emit(this.reloadBus);
                    }
                    this.$swal({
                        title: "Thành công",
                        text: "Xoá thành công",
                        icon: 'success',
                        toast: true,
                        position: 'bottom-end',
                        timer: 5000,
                    });
                }
            });
        },
    },
    mounted: function () {
        this.formModel = {};
        this.formModel = Object.assign({}, this.defaultFormModel);
    }
};