import fileReaderStream from 'filereader-stream';
import {saveAs} from 'file-saver'
import Excel from 'exceljs';
import moment from 'moment';

export default {
    data: function () {
        return {
            service: '/',
            reloadBus: null,
            show: false,
            file: null,
            reading: false,
            importing: false,
            updated: 0,
            totalRows: 0,
            // indexRow: 4,
            successCount: 0,
            errorCount: 0,
            importErrors: [],
            cancelRequest: false,
            templateName: 'template.xlsx',
        }
    },
    methods: {
        getDate(value, format) {
            try {
                if (typeof (value) === 'string') {
                    if (!format) {
                        format = "DD/MM/YYYY";
                    }
                    if (value.includes(":")) {
                        format = "DD/MM/YYYY HH:mm:ss";
                    }
                    value = moment(value, format, true).format("x");
                } else {
                   
                    value = moment(value.toUTCString().replace(" GMT", "")).format("x");
                }
            } catch (e) {
                if(format !== 'YYYY'){
                    value = 0;
                }
            }
            return value && value === 'Invalid date' ? null : value;
        },
        close(evt) {
            if (this.reading || this.importing) {
                evt.preventDefault();
            } else {
                this.reset();
            }
        },
        reset() {
            this.totalRows = 0;
            this.successCount = 0;
            this.errorCount = 0;
            this.importErrors = [];
            this.cancelRequest = false;
            this.reading = false;
            this.importing = false;
            this.file = null;
        },
        cancel() {
            this.cancelRequest = true;
        },
        downloadErrors() {
            console.log('loi k nhan');
            let workbook = new Excel.Workbook();
            let sheet = workbook.addWorksheet('Dữ liệu');
            let cols = [];
            let row = {};
            let row_desc = {};
            for (let i in this.headers) {
                let header = this.headers[i];
                cols.push(header);
                row[header.key] = header.label;
                row_desc[header.key] = header.desc;
            }
            sheet.columns = cols;
            sheet.addRow(row);
            sheet.addRow(row_desc);
            row.alignment = {wrapText: true};
                //ẩn dòng 1
            sheet.getRow(1).hidden = true;
            sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'bdbdbd'
                }
            };
            // đôi đậm và wrap text
            sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(2).font = {
                bold: true
            };
            //bôi màu
            sheet.getRow(2).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '92D050'
                }
            };
            //Dòng 3
            sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(3).font = {
                italic: true
            };
            let indexRow = 3
            
            for (let err of this.importErrors) {
                console.log(err.oldItem);
                sheet.addRow(err.oldItem);
                indexRow +=1;
                row = sheet.getRow(indexRow);
                for (let column of Object.keys(err.err.response.data)){
                    if(column == 'patient_relative_unique'){
                        row.getCell('code').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'F3F61D',
                            },
                        }
                        row.getCell('start_time').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'F3F61D',
                            },
                        }
                    }
                    if(column == 'patient_unique'){
                        row.getCell('code').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'F3F61D',
                            },
                        }
                        row.getCell('rate_time').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'F3F61D',
                            },
                        }
                    }
                    if(column == 'org_unique'){
                        row.getCell('name').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'F3F61D',
                            },
                        }
                        row.getCell('start_time').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'F3F61D',
                            },
                        }
                    }
                    if(Object.keys(err.oldItem).includes(column)){
                        row.getCell(column).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'F3F61D',
                            },
                        }
                    }
                }
            }
            let sheet2 = workbook.addWorksheet('Chi tiết lỗi');
            sheet2.columns = [
                {header: "Dòng tập tin excel", key: 'row', width: 15},
                {header: "Chi tiết vấn đề", key: 'error', width: 50}
            ];
            let indexrow2 = 3
            for (let err of this.importErrors) {
                sheet2.addRow({
                    row: indexrow2+=1,
                    error: (err.err.response && err.err.response.status === 422) ? Object.keys(err.err.response.data).map(i => `${i}: ${err.err.response.data[i]}`).join(', ') : err.err.message
                });
            }
            workbook.xlsx.writeBuffer({base64: true}).then(data => {
                saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                        'import-errors.xlsx');
            });
        },
        downloadTemplate: function () {
            let workbook = new Excel.Workbook();
            let sheet = workbook.addWorksheet('Dữ liệu');
            let cols = [];
            let row = {};
            let row_desc = {};
            for (let i in this.headers) {
                let header = this.headers[i];
                cols.push(header);
                row[header.key] = header.label;
                row_desc[header.key] = header.desc;
            }
            sheet.columns = cols;
            sheet.addRow(row);
            sheet.addRow(row_desc);
            row.alignment = {wrapText: true};
                //ẩn dòng 1
            sheet.getRow(1).hidden = true;
            sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'bdbdbd'
                }
            };
            // đôi đậm và wrap text
            sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(2).font = {
                bold: true
            };
            //bôi màu
            sheet.getRow(2).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '92D050'
                }
            };
            //Dòng 3
            sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            sheet.getRow(3).font = {
                italic: true
            };
            workbook.xlsx.writeBuffer({base64: true}).then(data => {
                saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                        this.templateName);
            });
        },
        process(evt) {
            evt.preventDefault();
            if (!this.file) {
                this.$swal({
                    title: "Thông báo",
                    text: "Vui lòng chọn tập tin cần tải lên hệ thống",
                    icon: 'error',
                });
                return;
            }
            if (this.file.name.endsWith('.xls') || this.file.name.endsWith('.xlsx')) {
                this.reading = true;
                let readStream = fileReaderStream(this.file);
                const workbook = new Excel.Workbook();
                workbook.xlsx.read(readStream).then(async () => {
                    if (workbook.worksheets.length === 0) {
                        throw new Error("File tải lên chưa đúng mẫu!");
                    }
                    let sheet = workbook.worksheets[0];
                    if (sheet.actualRowCount < this.indexRow) {
                        throw new Error("File tải lên không có dòng dữ liệu nào!");
                    }
                    if (this.validate) {
                        this.validate(sheet);
                    }
                    let headerRow = sheet.getRow(1);
                    let i = this.indexRow;
                    this.totalRows = sheet.actualRowCount - (this.indexRow - 1);
                    this.reading = false;
                    this.importing = true;
                    if (this.totalRows === 0) {
                        throw new Error("File tải lên không có dòng dữ liệu nào!");
                    }
                    while (i <= sheet.actualRowCount) {
                        if (this.cancelRequest) {
                            break;
                        }
                        let item = await this.getItem(headerRow, sheet.getRow(i));
                        let oldItem = await this.getImportItem(headerRow, sheet.getRow(i));
                        try {
                            if (this.save) {
                                await this.save(item);
                            } else {
                                await this.$service.post(`${this.service}/create`, item);
                            }
                            this.successCount++;
                        } catch (err) {
                            this.importErrors.push({row: i, err: err, oldItem : oldItem});
                            this.errorCount++;
                        }
                        i++;
                    }
                }).catch(err => {
                    this.$swal({
                        title: "Thông báo",
                        text: err.message,
                        icon: 'error'
                    });
                }).finally(() => {
                    this.reading = false;
                    this.importing = false;
                    this.cancelRequest = false;
                    if (this.reloadBus) {
                        this.$bus.$emit(this.reloadBus);
                    }
                });
            } else {
                this.$swal({
                    title: "Thông báo",
                    text: "File tải lên không đúng định dạng!",
                    icon: 'error',
                });
            }
        },
        getKeyOption(value){
            let key = [];
            if(value){
                for (let i in value){
                    let k = value[i];
                    if(k.value != null){
                        key.push(k.value.toString());
                    }
                }
            }
            return key;
        },
        async getImportItem(headerRow, row) {
            let item = {};
            for (let j = 1; j <= headerRow.cellCount; j++) {
                let key = headerRow.getCell(j).value;
                let value;
                if (row.getCell(j).isHyperlink) {
                    value = row.getCell(j).value.text;
                } else {
                    value = row.getCell(j).value;
                }
                item[key] = value;
            }
            return item;
        }
    }
};